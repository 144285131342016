* {
  font-style: normal !important;
  font-family: 'Inter' !important;
}

/* .ant-menu {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
} */

.ant-menu-item-icon {
  width: 24px !important;
  height: 24px !important;
}

.ant-menu-title-content {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #38003c !important;
}

.ant-menu-item-selected {
  background-color: #a8d6ea !important;
}

.ant-menu-item {
  border-radius: 0 !important;
  height: 48px !important;
}

.ant-menu-item-icon {
  fill: #000 !important;
}

/* .ant-dropdown-link {
  column-gap: 16px !important;
}

.ant-dropdown {
  left: unset !important;
  right: 50px !important;
  top: 70px !important;
} */

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-tabs-nav-wrap {
  background-color: #fefefe !important;
}

.ant-tabs-tab {
  color: #8a8a8a !important;
  padding: 10px 16px !important;
  margin: 4px !important;
  background-color: unset !important;
  border: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}

.ant-tabs-tab-active {
  background-color: #90258c !important;
  border-radius: 4px !important;
}

.ant-tabs-nav {
  box-shadow: 0px 0px 24px rgba(18, 2, 36, 0.08) !important;
  border-radius: 4px !important;
}

.antd-prev-next {
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  width: 88px;
  height: 48px !important;
  border: 2px solid #90258c;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #90258c;
}

.ant-pagination-prev {
  height: 48px !important;
}
.ant-pagination-next {
  height: 48px !important;
}

.ant-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ant-pagination .ant-pagination-item {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: 48px;
  height: 48px;
  background: #fefefe;
  border: 1px solid #d8d8d8;
  border-radius: 4px;

  /* style */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
}

.ant-drawer-title {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  color: #120224 !important;
}

.ant-breadcrumb ol {
  column-gap: 8px !important;
}

.ant-breadcrumb ol li span {
  cursor: pointer !important;
  padding: 12px !important;

  background: #8a8a8a !important;
  color: #fefefe !important;
  border-radius: 4px !important;
}

.ant-breadcrumb li:last-child span {
  background: #38003c !important;
}

.ant-btn {
  background-color: #90258c !important;
  border-color: #90258c !important;
  border-radius: 4px !important;
  border: 2px solid #90258c !important;
  color: #fefefe !important;
  cursor: pointer !important;
}

.ant-btn:hover {
  background-color: #4c1a50 !important;
  border-color: #4c1a50 !important;
  color: #fefefe !important;
}

.ant-btn:active {
  background-color: #38003c !important;
  border-color: #38003c !important;
  color: #fefefe !important;
}

.ant-btn:focus {
  background-color: #90258c !important;
  border-color: #90258c !important;
  color: #fefefe !important;
}

.ant-btn:disabled {
  cursor: not-allowed !important;
  background-color: #d8d8d8 !important;
  border: none;
  color: #fefefe !important;
}

.confirm-delete-modal .ant-modal-content .ant-modal-footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.confirm-delete-modal .ant-modal-content .ant-modal-footer .ant-btn-default {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  background: #fefefe !important;
  border-radius: 4px !important;

  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: #120224 !important;

  padding: 24px 32px !important;
}

.confirm-delete-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  background: #e73f3f !important;
  border-radius: 4px !important;

  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: #fefefe !important;

  padding: 24px 32px !important;
}

.edit-asset-modal .ant-modal-content .ant-modal-footer {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.edit-asset-modal .ant-modal-content .ant-modal-footer .ant-btn-default {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  background: #fefefe !important;
  border-radius: 4px !important;

  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: #90258c !important;

  padding: 24px 32px !important;
}

.edit-asset-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  background: #90258c !important;
  border-radius: 4px !important;

  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: #fefefe !important;

  padding: 24px 32px !important;
}

.ant-input-number {
  width: 100% !important;
}

.ant-input-number-input {
  padding: 0 !important;
}

input.ant-input-number-input {
  height: unset !important;
}

.ant-input-number .ant-input-number-input {
  height: unset !important;
  vertical-align: middle !important;
}

.ant-input-number-affix-wrapper {
  width: 100% !important;
}

.ant-input-number-affix-wrapper .ant-input-number {
  padding: 0 !important;
}
