body {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  background-color: #90258c;
  border-color: #90258c;
  border-radius: 4px;
  color: #fefefe;
  cursor: pointer;
}

button:hover {
  background-color: #4c1a50 !important;
  border-color: #4c1a50 !important;
  color: #fefefe !important;
}

button:active {
  background-color: #38003c !important;
  border-color: #38003c !important;
  color: #fefefe !important;
}

button:focus {
  background-color: #90258c !important;
  border-color: #90258c !important;
  color: #fefefe !important;
}

button:disabled {
  cursor: not-allowed !important;
  background-color: #d8d8d8 !important;
  border: none;
  color: #fefefe !important;
  border-color: #fefefe !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content .InovuaReactDataGrid__sort-icon-wrapper {
  display: none !important;
}
